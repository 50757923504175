<!-- Content -->
<form [formGroup]="TaskApportunityInfoForm">
  <div class="mb-3">
    <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.TASK-TITLE' | translate}}</h3>
    <p *ngIf="!taskTitleEdit" (click)="taskTitleEdit = true"
      class="mb-0 font-bold text-sm md:text-base cursor-pointer border-round hover:bg-gray-200">
      {{modelData.latest_task?modelData.latest_task.title:modelData.title}}</p>

    <div *ngIf="taskTitleEdit" class="p-inputgroup">
      <input type="text" class="w-full text-sm" pInputText formControlName="taskTitle" />
      <button type="button" pButton pRipple icon="pi pi-check"
        (click)="EditTaskTitle(TaskApportunityInfoForm)"></button>
    </div>
  </div>

  <div class="mb-4 border-bottom-1 border-gray-300 pb-3 relative">
    <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.TASK-NOTES' | translate}}</h3>
    <div *ngIf="!taskNotesEdit" (click)="taskNotesEdit = true" style="padding: 0.5em 0.75em;" class="mb-0 text-sm text-gray-700">
      <span class="cursor-pointer border-round hover:bg-gray-200">{{taskNotes |
        slice:0:100}} </span>
      <span *ngIf="taskNotes.length > 100"
        class="text-primary cursor-pointer font-bold d-inline-block ml-2 hover:underline" pStyleClass="@next"
        enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
        [hideOnOutsideClick]="true">{{'Shared.TASK-OPPORTUNITY-INFO.LOAD_MORE' | translate}}</span>

      <div
        class="task-notes-more hidden bg-white p-3 z-3 border-round absolute shadow-2 border-1 border-gray-300 left-0 w-full text-sm">
        {{taskNotes}}
      </div>
    </div>
    <div *ngIf="taskNotesEdit" class="p-inputgroup">
      <input type="text" class="w-full text-sm" pInputText formControlName="taskNotes" />
      <button type="button" pButton pRipple icon="pi pi-check"
        (click)="EditTaskNotes(TaskApportunityInfoForm)"></button>
    </div>
  </div>

  <div class="grid">
    <div class="col-6">
      <h3 class="text-xs text-gray-600 mb-3 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.TASK-TYPE' | translate}}</h3>
      <p class="mb-0 -mt-1 font-bold"><i
          class="pi pi-phone mr-2"></i>{{modelData.latest_task?modelData.latest_task.type:modelData.type }}</p>
    </div>
    <div class="col-6">
      <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.ASSIGNED-TO' | translate}}</h3>
      <div class="ml-0">
        <staff-selector [formGroup]="TaskApportunityInfoForm" [controlName]="SelectedAssignedFormControl"
          [id]="assignedTo$" [styleClass]="'border-none surface-50 w-full'"
          [placeHolder]="'common.PLACEHOLDER.SELECT' | translate"
          (selectedStaff)="getSelectedStaff($event)" >
        </staff-selector>
      </div>
    </div>
    <div class="col-6">
      <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.DUE' | translate}}</h3>
      <strong *ngIf="!dueDateEdit" (click)="dueDateEdit = true"
        class="text-sm block md:inline md:text-base -mt-2 p-1 -ml-1 md:mt-0 cursor-pointer border-round hover:bg-gray-200">
        {{TaskApportunityInfoForm.get('scheduled_date').value | datetimeformat: 'EE, MMMM d, y'}}</strong>
      <div *ngIf="dueDateEdit" class="p-inputgroup">
        <p-calendar [showIcon]="true" formControlName="scheduled_date" styleClass="w-full" placeholder="Select Date"
          inputStyleClass="text-sm" (onSelect)="OnScheduleDateSelect($event)"></p-calendar>
      </div>
    </div>
    <div class="col-6" *ngIf="modelData.stage_master_id">
      <h3 class="text-xs text-gray-600 mb-0 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.CONVERTIBILITY' | translate}}
      </h3>
      <div class="-ml-3">
        <p-dropdown [options]="convertibility" formControlName="ddlselectedConvertibility" optionLabel="name"
          styleClass="border-none surface-50" (onChange)="UpdateConvartability($event,TaskApportunityInfoForm)">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center" *ngIf="SelectedConvertibility">
              <span class="mr-2"><img width="20" src="{{SelectedConvertibility.icon}}"
                  alt="{{SelectedConvertibility.name}}" /></span>
              <strong class="text-900">{{SelectedConvertibility.name}}</strong>
            </div>
          </ng-template>
          <ng-template let-convertibility pTemplate="item">
            <div class="flex align-items-center">
              <span class="mr-2"><img width="20" class="grayscale opacity-50" src="{{convertibility.icon}}"
                  alt="{{convertibility.name}}" /></span>
              <div class="text-sm text-gray-600">{{convertibility.name}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="col-6" *ngIf="modelData.stage_master_id">
      <h3 class="text-xs text-gray-600 mb-0 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.OPPORTUNITY-STAGE' |
        translate}}
      </h3>
      <div class="-ml-2">
        <p-dropdown [options]="opportunityStageCollection" formControlName="ddlselectedOpportunity" optionLabel="name"
          styleClass="border-none surface-50" (onChange)="UpdateOpportunityStatge($event,TaskApportunityInfoForm)">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="SelectedOpportunity">
              <strong class="text-900">{{SelectedOpportunity.name}}</strong>
            </div>
          </ng-template>
          <ng-template let-opportunity pTemplate="item">
            <div class="text-sm">{{opportunity.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="col-6" *ngIf="modelData.stage_master_id">
      <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.AMOUNT' | translate}}</h3>
      <strong *ngIf="!amountEdit" (click)="amountEdit = true"
        class="text-sm block md:inline md:text-base -mt-2 p-1 md:mt-0 cursor-pointer border-round hover:bg-gray-200">
        {{modelData.value_in_minor_currency?(modelData.value_in_minor_currency
        | currencyformat):(0 | currencyformat) }}</strong>

      <div *ngIf="amountEdit" class="p-inputgroup">

        <span class="p-inputgroup-addon">{{this.country.currency_symbol}}</span>
        <p-inputNumber  class="w-full text-sm" formControlName="value_in_minor_currency">
        </p-inputNumber>
        <button type="button" pButton pRipple icon="pi pi-check" class="px-3"
          (click)="UpdateAmount(TaskApportunityInfoForm)"></button>
      </div>
    </div>
    <div class="col-12" *ngIf="modelData.stage_master_id">
      <h3 class="text-xs text-gray-600 mb-2 font-normal">{{'Shared.TASK-OPPORTUNITY-INFO.EXPECTED-CLOSURE-MONTH' |
        translate}}</h3>
      <strong *ngIf="!expectedDateEdit" (click)="expectedDateEdit = true"
        class="text-sm block md:inline md:text-base -mt-2 p-1 -ml-1 md:mt-0 cursor-pointer border-round hover:bg-gray-200">
        <i class="pi pi-calendar-plus mr-2"></i>{{TaskApportunityInfoForm.get('expected_closure_date').value | datetimeformat: 'MMMM YYYY'}}
      </strong>
      <div *ngIf="expectedDateEdit" class="p-inputgroup">
        <p-calendar [showIcon]="true" formControlName="expected_closure_date" styleClass="w-full"
          placeholder="Select Date" inputStyleClass="text-sm" (onSelect)="OnExpectedClosureDateSelect($event)">
        </p-calendar>
      </div>

    </div>
    <div class="col-12" *ngIf="modelData.stage_master_id">
      <h3 class="text-xs text-gray-600 mb-2 font-normal mt-2">{{'Shared.TASK-OPPORTUNITY-INFO.INTERESTED-IN' |
        translate}}
      </h3>
      <p-autoComplete formControlName="service_type_ids" field="name_primary_language" styleClass="w-full"
        [suggestions]="filtertedServiceType" (completeMethod)="filterServiceType($event)"
        (onSelect)="UpdateServiceType(false, $event, TaskApportunityInfoForm)"
        (onUnselect)="UpdateServiceType(true, $event, TaskApportunityInfoForm)" [multiple]="true"></p-autoComplete>
    </div>
  </div>
</form>
<!-- Content -->
<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>
