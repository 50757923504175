import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponentModule } from '../header/header.component';

@Component({
  selector: 'fitness-force-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent implements OnInit {
  errCode: string = '';
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.componentInit();
  }

  componentInit(): void {
    this.route.params.subscribe(params => {
      if (params["errcode"]) {
        this.errCode = params["errcode"];
      }
    });
  }
}

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    CommonModule,
    HeaderComponentModule,
  ],
  exports: [PageNotFoundComponent]
})
export class PageNotFoundComponentModule {
}
