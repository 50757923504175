<!-- Body Container -->
<div class="min-h-screen surface-100 text-sm pb-6 md:mb-0">

    <!-- Header -->
    <ff-paylink-header [showTimeline]="false"></ff-paylink-header>
    <!-- Header -->

    <!-- Content -->
    <ng-container *ngIf="errCode == ''">
        <div class="w-12 md:w-7 xl:w-5 mx-auto p-3 md:px-0 pb-8 md:pb-0 mb-6 md:mb-0">
            <h2 class="p-error text-7xl my-6 text-center">Oops!</h2>
            <div class="text-center my-6">
                <img src="/assets/img/expired-link-img.svg" alt="Link Expired Image" />
            </div>

            <div class="text-center mt-6">
                <h3 class="text-xl md:text-3xl mb-2">Sorry! This link is expired.</h3>
                <p class="text-sm">Please try again!</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errCode == 'signuplinkexpire'">
        <div class="w-12 md:w-7 xl:w-5 mx-auto p-3 md:px-0 pb-8 md:pb-0 mb-6 md:mb-0">
            <h2 class="p-error text-7xl my-6 text-center">Oops!</h2>
            <div class="text-center my-6">
                <img src="/assets/img/expired-link-img.svg" alt="Link Expired Image" />
            </div>

            <div class="text-center mt-6">
                <h3 class="text-xl md:text-3xl mb-2">Sorry! This link is expired.</h3>
                <p class="text-sm">Please try again!</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errCode == 'awaitingpayment'">
        <div class="w-12 md:w-7 xl:w-5 mx-auto p-3 md:px-0 pb-8 md:pb-0 mb-6 md:mb-0">
            <h2 class="text-7xl my-6 text-center"><img src="/assets/img/awaiting-payment-text.svg"
                    alt="Awaiting Payment" /></h2>
            <div class="text-center my-6">
                <img src="/assets/img/bank-payment-img.svg" alt="Bank Payment Image" />
            </div>

            <div class="text-center mt-6">
                <h3 class="text-2xl md:text-3xl mb-0">We are awaiting payment from the bank.</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errCode == 'subscriptioncreated'">
        <div class="w-12 md:w-7 xl:w-5 mx-auto p-3 md:px-0 mb-8 relative">
            <img class="block mx-auto my-6" src="/assets/img/yay-text.svg" alt="Yay!" />
            <div class="text-center my-6">
                <img src="/assets/img/thank-you-img.svg" alt="Image" />
            </div>

            <div class="text-center mt-6">
                <h3 class="text-xl md:text-3xl mb-2">Your subscription has already been created!</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errCode == 'notfound'">
        <div class="w-12 md:w-7 xl:w-5 mx-auto p-3 md:px-0 pb-8 md:pb-0 mb-6 md:mb-0">
            <h2 class="p-error text-7xl my-6 text-center"><img src="/assets/img/oops-text.svg" alt="Oops" /></h2>
            <div class="text-center my-6">
                <img src="/assets/img/expired-link-img.svg" alt="Link Expired Image" />
            </div>

            <div class="text-center mt-6">
                <h3 class="text-xl md:text-3xl mb-2">Your subscription details cannot not found!</h3>
                <p class="text-lg text-gray-700">Please try again!</p>
            </div>
        </div>
    </ng-container>
    <!-- Content -->

</div>
<!-- Body Container -->