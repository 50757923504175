import { createAction, props } from "@ngrx/store";

export const FETCH_CITY_MASTER_LIST = createAction(
    'P_FETCHCITYMASTERLIST',
    props<{ cities: any }>()
);

export const FETCH_CITY_MASTER = createAction(
    'P_FETCHCITYMASTER',
    props<{ city: any }>()
);