import { CompanyInfoStoreInterface, PayLinkStepOption, PayLinkStepOptionPayload, SubscriptionPayload, SubscriptionSignUpOrderSummaryResponse, TENANTSINTERFACE } from "@fitness-force/models";
import { createReducer, on } from "@ngrx/store";
import { FETCH_CITY_MASTER, FETCH_CITY_MASTER_LIST } from "./actions/city.action";
import { DIRECTION_DETAILS, ERROR_COMPANY_DETAILS, FETCHED_COMPANY_DETAILS, SELECT_LANGUAGE } from "./actions/company.action";
import { FETCH_COUNTRY_MASTER, FETCH_COUNTRY_MASTER_LIST } from "./actions/country.action";
import { FETCHED_SIGNUPSTEP_INFO } from "./actions/signupsteps";
import { FETCH_STATE_MASTER, FETCH_STATE_MASTER_LIST } from "./actions/state.action";
import { ERROR_SUBSCRIPTION_PAYLOAD, FETCHED_SUBSCRIPTION_PAYLOAD } from "./actions/subscription-payload";
import { FETCHED_TENANT_DETAILS, FETCHED_TENANT_LIST } from "./actions/tenant.action";
import { CITY_MASTER_STATE } from "./store/city-master.store";
import { INITIAL_COMPANY_STATE } from "./store/company.store";
import { COUNTRY_MASTER_STATE } from "./store/country-master.store";
import { SUBSCRIPTION_PAYLOAD_STATE } from "./store/order-summary";
import { SIGNUP_STEPS_STATE } from "./store/signupsteps.store";
import { STATE_MASTER_STATE } from "./store/state-master.store";
import { TENANT_STATE } from "./store/tenant.store";

export const subscriptionPayloadReducer = createReducer(SUBSCRIPTION_PAYLOAD_STATE,
    on(FETCHED_SUBSCRIPTION_PAYLOAD, (state, action): SubscriptionPayload => {
        return {
            ...state,
            orderSummary: action.orderSummary
        };
    }),
    on(ERROR_SUBSCRIPTION_PAYLOAD, (state, action): SubscriptionPayload => {
        return {
            ...state,
            err: action.err
        };
    })
);

export const SignUpPaylinkReducer = createReducer(SIGNUP_STEPS_STATE,
    on(FETCHED_SIGNUPSTEP_INFO, (state, action): PayLinkStepOptionPayload => {
        return {
            ...state,
            steps: action.steps
        };
    })
);

export const companyReducer = createReducer(INITIAL_COMPANY_STATE,
    on(FETCHED_COMPANY_DETAILS, (state, action): CompanyInfoStoreInterface => {
        return {
            ...state,
            compDetails: action.companyDetails
        };
    }),
    on(ERROR_COMPANY_DETAILS, (state, action): CompanyInfoStoreInterface => {
        return {
            ...state,
            err: action.err
        };
    }),
    on(SELECT_LANGUAGE, (state, action): CompanyInfoStoreInterface => {
        return {
            ...state,
            selectedLang: action.lang
        };
    }),
    on(DIRECTION_DETAILS, (state, action): CompanyInfoStoreInterface => {
        return {
            ...state,
            direction: action.dir
        };
    }),
)

export const tenantReducer = createReducer(TENANT_STATE,
    on(FETCHED_TENANT_LIST, (state, action): TENANTSINTERFACE => {
        return {
            ...state,
            accessed_tenant: action.accessed_tenant
        };
    }),
    on(FETCHED_TENANT_DETAILS, (state, action): TENANTSINTERFACE => {
        return {
            ...state,
            selected_tenant: action.selected_tenant
        };
    })
)

export const countryListReducer = createReducer(COUNTRY_MASTER_STATE,
    on(FETCH_COUNTRY_MASTER_LIST, (state, action): any => {
        return {
            ...state,
            countries: action.countries
        };
    }),
    on(FETCH_COUNTRY_MASTER, (state, action): any => {
        return {
            ...state,
            country: action.country
        };
    })
)

export const stateListReducer = createReducer(STATE_MASTER_STATE,
    on(FETCH_STATE_MASTER_LIST, (state, action): any => {
        return {
            ...state,
            states: action.states
        };
    }),
    on(FETCH_STATE_MASTER, (state, action): any => {
        return {
            ...state,
            state: action.state
        };
    })
)

export const cityListReducer = createReducer(CITY_MASTER_STATE,
    on(FETCH_CITY_MASTER_LIST, (state, action): any => {
        return {
            ...state,
            cities: action.cities
        };
    }),
    on(FETCH_CITY_MASTER, (state, action): any => {
        return {
            ...state,
            city: action.city
        };
    })
);