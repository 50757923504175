import { Injectable } from "@angular/core";
import { SubscriptionSignupByUuidService } from "@fitness-force/api";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, forkJoin, map, mergeMap, Observable, of, switchMap } from "rxjs";
import { environment } from "../../environments/environment";
import { ERROR_COMPANY_DETAILS, FETCHED_COMPANY_DETAILS, SELECT_LANGUAGE } from "./actions/company.action";
import { FETCHED_SUBSCRIPTION_PAYLOAD, ERROR_SUBSCRIPTION_PAYLOAD } from "./actions/subscription-payload";
import { FETCHED_TENANT_DETAILS, ERROR_TENANT_LIST } from "./actions/tenant.action";
import { CONST_CITIES, CONST_COUNTRIES, CONST_STATES } from '@fitness-force/constants';
import { Cities, City, ClientMandatoryFieldResponse, Countries, Country, PayLinkStepOption, State, States, SubscriptionSignUpOrderSummaryResponse, WaiverMasterResponseList } from "@fitness-force/models";
import { FETCH_COUNTRY_MASTER, FETCH_COUNTRY_MASTER_LIST } from "./actions/country.action";
import { FETCH_STATE_MASTER, FETCH_STATE_MASTER_LIST } from "./actions/state.action";
import { FETCH_CITY_MASTER, FETCH_CITY_MASTER_LIST } from "./actions/city.action";
import { FETCHED_SIGNUPSTEP_INFO } from "./actions/signupsteps";
import { PayLinkSteps } from "@fitness-force/enum";


@Injectable()
export class CompanyEffects {

  constructor(
    private actions$: Actions,
    private subscriptionSignUp: SubscriptionSignupByUuidService,
  ) {
  }

  getCountryState(tenantPayload: any): Countries {
    let countries: Country[] = CONST_COUNTRIES;
    if (tenantPayload) {
      let country: Country = countries.find((o: Country) => {
        return (
          String(o.three_letter_code).toLowerCase() == String(tenantPayload.country).toLowerCase()
          || String(o.two_letter_code).toLowerCase() == String(tenantPayload.country).toLowerCase()
        );
      }) || {} as Country;

      return { countries: countries, country: country };
    }
    return { countries: countries, country: {} as Country };
  }

  getStatesState(tenantPayload: any): States {
    let states: State[] = CONST_STATES;
    if (tenantPayload) {
      let state: State = states.find((o: State) => {
        return (String(o.code).toLowerCase() == String(tenantPayload.state).toLowerCase());
      }) || {} as State;

      return { states: states, state: state };
    }
    return { states: states, state: {} as State };
  }

  getCitiesState(tenantPayload: any): Cities {
    let cities: City[] = CONST_CITIES;
    if (tenantPayload) {
      let city: City = cities.find((o: City) => {
        return (String(o.code).toLowerCase() == String(tenantPayload.city).toLowerCase());
      }) || {} as City;

      return { cities: cities, city: city };
    }
    return { cities: cities, city: {} as City };
  }

  getOrderSummary$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCHED_SUBSCRIPTION_PAYLOAD'),
      switchMap((param: any) => this.subscriptionSignUp.GetOrderSummary(environment.BASE_URL, param.uuid)),
      mergeMap((subscriptionDetails: any) => {
        return [FETCHED_SUBSCRIPTION_PAYLOAD({ orderSummary: subscriptionDetails }),]
      }),
      catchError((err) => {
        if (err.originalError.status == '0')
          return of(ERROR_SUBSCRIPTION_PAYLOAD({ err: { details: { status: '0', errResponse: '' }, status: true } }))
        else
          return of(ERROR_SUBSCRIPTION_PAYLOAD({ err: { details: { status: err.originalError.status, errResponse: err.originalError.error }, status: true } }))
      })
    )
  });

  getSignupSteps$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('SIGNUPSTEP_INFO'),
      switchMap((param: any) => {
        let waiverPayload: any = this.subscriptionSignUp.GetWaivers(environment.BASE_URL, param.uuid);
        let clientMandatoryFieldPayload: any = this.subscriptionSignUp.GetClientMandatoryField(environment.BASE_URL, param.uuid);
        return forkJoin({ waiver: waiverPayload, mandate: clientMandatoryFieldPayload });
      }),
      mergeMap((stepsPayload: any) => {
        let steps: PayLinkStepOption[] = [];
        steps.push({ name: PayLinkSteps.Details, isProcessed: true, data: {}, redirectPath: 'details', isCurrentPage: true } as PayLinkStepOption);

        let waiver = stepsPayload.waiver as WaiverMasterResponseList;

        if (waiver && waiver.items && waiver.items.length > 0)
          steps.push({ name: PayLinkSteps.Waiver, isProcessed: false, data: waiver.items, redirectPath: 'waiver', isCurrentPage: false } as PayLinkStepOption);

        let clientMandateInfo = stepsPayload.mandate as ClientMandatoryFieldResponse;

        if (false && clientMandateInfo && clientMandateInfo.field_required && Object.keys(clientMandateInfo.field_required).length > 0)
          steps.push({ name: PayLinkSteps.Information, isProcessed: false, data: clientMandateInfo, redirectPath: 'info', isCurrentPage: false } as PayLinkStepOption);

        steps.push({ name: PayLinkSteps.Payment, isProcessed: false, data: {}, redirectPath: 'payment', isCurrentPage: false } as PayLinkStepOption);

        return [FETCHED_SIGNUPSTEP_INFO({ steps: steps })]
      }),
      catchError((err) => {
        return of(console.log(err));
      })
    );
  });

  getCompany$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCHED_COMPANY_DETAILS'),
      switchMap((param: any) => this.subscriptionSignUp.GetCompany(environment.BASE_URL, param.uuid)),
      map((companyPayLoad: any) => {
        console.log(companyPayLoad);
        return FETCHED_COMPANY_DETAILS({ companyDetails: companyPayLoad })
      }),
      catchError(async (err) => ERROR_COMPANY_DETAILS({ err: { details: { status: err.originalError.status, errResponse: err.originalError.error }, status: true } }))
    );
  });

  getTenantAndSubDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCHED_TENANT_LIST'),
      switchMap((param: any) => this.subscriptionSignUp.GetTenant(environment.BASE_URL, param.uuid)),
      mergeMap((tenantPayLoad) => {
        console.log('tenant -> ' + JSON.stringify(tenantPayLoad))
        let countryPayload: Countries = this.getCountryState(tenantPayLoad);
        let statePayload: States = this.getStatesState(tenantPayLoad);
        let cityPayload: Cities = this.getCitiesState(tenantPayLoad);
        return [
          FETCHED_TENANT_DETAILS({ selected_tenant: tenantPayLoad }),
          SELECT_LANGUAGE({ lang: { COUNTRY: 'India', LANG_ABBRE: 'India', 'LANG_CODE': 'en' } }),
          FETCH_COUNTRY_MASTER_LIST({ countries: countryPayload.countries }),
          FETCH_COUNTRY_MASTER({ country: countryPayload.country }),
          FETCH_STATE_MASTER_LIST({ states: statePayload.states }),
          FETCH_STATE_MASTER({ state: statePayload.state }),
          FETCH_CITY_MASTER_LIST({ cities: cityPayload.cities }),
          FETCH_CITY_MASTER({ city: cityPayload.city }),
        ]
      }),
      catchError(async (err) => ERROR_TENANT_LIST({ err: { details: { status: err.originalError.status, errResponse: err.originalError.error }, status: true } }))
    );
  });
}
