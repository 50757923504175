import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';

const appRoutes: Routes = [
    {
        path: '',
        component: PageNotFoundComponent
    },
    {
        path: ':uuid', loadChildren: () => import('./features/client-payment/client-payment.module').then(c => c.ClientPaymentModule)
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes)

    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}