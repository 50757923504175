import { Cities, CompanyInfoStoreInterface, Countries, PayLinkStepOption, PayLinkStepOptionPayload, States, SubscriptionPayload, SubscriptionSignUpOrderSummaryResponse, TENANTSINTERFACE } from "@fitness-force/models";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const getSubscriptionPayloadState = createFeatureSelector<SubscriptionPayload>('subscriptionpayload');
export const SUBSCRIPTION_PAYLOAD = createSelector(
    getSubscriptionPayloadState,
    state => state
);

const getSignUpPaylinkState = createFeatureSelector<PayLinkStepOption[]>('signuppaylinksteps');
export const SIGNUPSTEP_INFO = createSelector(
    getSignUpPaylinkState,
    state => state
);

const getCompanyFeatureState = createFeatureSelector<CompanyInfoStoreInterface>('company');
export const COMPANY_INFO = createSelector(
    getCompanyFeatureState,
    state => state
);

const getTenantsState = createFeatureSelector<TENANTSINTERFACE>('tenants');
export const TENANTS_INFO = createSelector(
    getTenantsState,
    state => state
);

const getCountryMasterListState = createFeatureSelector<Countries>('countries');
export const COUNTRY_MASTER_STATE_LIST = createSelector(
    getCountryMasterListState,
    state => state.countries
);

const getCountryState = createFeatureSelector<Countries>('countries');
export const COUNTRY_MASTER_STATE = createSelector(
    getCountryState,
    state => state.country
);

const getStateMasterListState = createFeatureSelector<States>('states');
export const STATE_MASTER_STATE_LIST = createSelector(
    getStateMasterListState,
    state => state.states
);

const getState_State = createFeatureSelector<States>('states');
export const STATE_MASTER_STATE = createSelector(
    getState_State,
    state => state.state
);


const getCityMasterListState = createFeatureSelector<Cities>('cities');
export const CITY_MASTER_STATE_LIST = createSelector(
    getCityMasterListState,
    state => state.cities
);

const getCityState = createFeatureSelector<Cities>('cities');
export const CITY_MASTER_STATE = createSelector(
    getCityState,
    state => state.city
);