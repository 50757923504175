import { createAction, props } from "@ngrx/store";

export const FETCH_STATE_MASTER_LIST = createAction(
    'P_FETCHSTATEMASTERLIST',
    props<{ states: any }>()
);

export const FETCH_STATE_MASTER = createAction(
    'P_FETCHSTATEMASTER',
    props<{ state: any }>()
);