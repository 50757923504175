import { SubscriptionSignUpOrderSummaryResponse } from '@fitness-force/models';
import { createAction, props } from '@ngrx/store';


/* COMPANY DETAILS*/
export const FETCHED_SUBSCRIPTION_PAYLOAD = createAction(
    'FETCHEDSUBSCRIPTIONPAYLOAD',
    props<{ orderSummary: SubscriptionSignUpOrderSummaryResponse }>()
);

export const ERROR_SUBSCRIPTION_PAYLOAD = createAction(
    'ERRORSUBSCRIPTIONPAYLOAD',
    props<{ err: any }>()
);

