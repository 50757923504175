import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineModule } from 'primeng/timeline';
import { Store } from '@ngrx/store';
import { COMPANY_INFO, SIGNUPSTEP_INFO } from '../../state/app.state';
import { CompanyInfoStoreInterface, PayLinkStepOption } from '@fitness-force/models';
import { map, Observable } from 'rxjs';
import { PayLinkSteps } from '@fitness-force/enum';

@Component({
  selector: 'ff-paylink-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() showTimeline: boolean = true;
  @Input('currentStep') payLinkSteps: PayLinkSteps = PayLinkSteps.Details;
  companyDetails$: Observable<any> = new Observable();
  constructor(
    public store: Store,
  ) {
    this.componentInit();
  }

  ngOnInit(): void {

  }

  componentInit(): void {
    this.companyDetails$ = this.store.select(COMPANY_INFO);
  }

  get paylinkSignUpSteps$(): Observable<any[]> {
    return this.store.select(SIGNUPSTEP_INFO).pipe(
      map((obj: any) => {
        console.log(obj);
        let steps: PayLinkStepOption[] = obj.steps;
        return steps.map((o: any) => this.getTitleNode(o));
      })
    );
  }

  getTitleNode(x: PayLinkStepOption): any {
    if (x.name == PayLinkSteps.Details || x.name.toString() == PayLinkSteps[PayLinkSteps.Details]) {
      let isCurrentPage: boolean = (this.payLinkSteps == PayLinkSteps.Details || this.payLinkSteps.toString() == PayLinkSteps[PayLinkSteps.Details]);
      return {
        title: PayLinkSteps[PayLinkSteps.Details], status: x.isProcessed || isCurrentPage, isCurrentPage: isCurrentPage
      };
    }
    else if (x.name == PayLinkSteps.Waiver || x.name.toString() == PayLinkSteps[PayLinkSteps.Waiver]) {
      let isCurrentPage: boolean = (this.payLinkSteps == PayLinkSteps.Waiver || this.payLinkSteps.toString() == PayLinkSteps[PayLinkSteps.Waiver]);
      return {
        title: PayLinkSteps[PayLinkSteps.Waiver], status: x.isProcessed || isCurrentPage, isCurrentPage: isCurrentPage
      };
    }
    else if (x.name == PayLinkSteps.Information || x.name.toString() == PayLinkSteps[PayLinkSteps.Information]) {
      let isCurrentPage: boolean = (this.payLinkSteps == PayLinkSteps.Information || this.payLinkSteps.toString() == PayLinkSteps[PayLinkSteps.Information]);
      return {
        title: PayLinkSteps[PayLinkSteps.Information], status: x.isProcessed || isCurrentPage, isCurrentPage: isCurrentPage
      };
    }
    else if (x.name == PayLinkSteps.Payment || (x.name as PayLinkSteps).toString() == PayLinkSteps[PayLinkSteps.Payment]) {
      let isCurrentPage: boolean = (this.payLinkSteps == PayLinkSteps.Payment || this.payLinkSteps.toString() == PayLinkSteps[PayLinkSteps.Payment]);
      return {
        title: PayLinkSteps[PayLinkSteps.Payment], status: x.isProcessed || isCurrentPage, isCurrentPage: isCurrentPage
      };
    }
    else {
      console.log('wrong step encountered', x);
      alert('wrong step encountered');
      return {};
    }
  }
}

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    TimelineModule
  ],
  exports: [HeaderComponent]
})
export class HeaderComponentModule {
}
