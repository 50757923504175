<!-- Header -->
<div class="bg-white shadow-2 p-3 text-center">
    <div class="w-12 md:w-7 xl:w-5 mx-auto">
        <div class="text-center">
            <img class="inline-block -ml-2 md:-ml-5 w-5rem md:w-8rem" [src]="(companyDetails$ | async).compDetails.logo"
                [alt]="(companyDetails$ | async).compDetails.app_type" />
        </div>

        <ng-container *ngIf="showTimeline">
            <p-timeline *ngIf="(paylinkSignUpSteps$ | async) as signUpSteps; else stepLoader" [value]="signUpSteps"
                layout="horizontal" styleClass="-my-3 ml-1 md:ml-0">
                <ng-template pTemplate="marker" let-event>
                    <span *ngIf="event.status"
                        class="custom-marker h-1rem w-1rem border-1 border-round bg-primary border-primary"></span>
                    <span *ngIf="!event.status"
                        class="custom-marker h-1rem w-1rem border-1 border-round bg-white border-gray-400"></span>
                </ng-template>
                <ng-template pTemplate="content" let-progress>
                    <div class="text-xxs md:text-xs white-space-nowrap -mt-2 -ml-2 md:-ml-3">
                        <strong *ngIf="progress.isCurrentPage">{{progress.title}}</strong>
                        <span *ngIf="!progress.isCurrentPage" class="text-gray-600">{{progress.title}}</span>
                    </div>
                </ng-template>
            </p-timeline>

            <ng-template #stepLoader>

            </ng-template>
        </ng-container>
    </div>
</div>
<!-- Header -->