import { PayLinkSteps } from '@fitness-force/enum';
import { PayLinkStepOption, PayLinkStepOptionPayload } from '@fitness-force/models';
import { createAction, props } from '@ngrx/store';


/* COMPANY DETAILS*/
export const FETCHED_SIGNUPSTEP_INFO = createAction(
    'FETCHEDSIGNUPSTEPINFO',
    props<{ steps: PayLinkStepOption[] }>()
);

export const SIGNUPSTEP_INFO = createAction(
    'SIGNUPSTEPINFO',
    props<{ steps: PayLinkStepOption[] }>()
);

