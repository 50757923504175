   <!-- Edit Profile Modal  -->
   <p-dialog [(visible)]="editProfileModal" [resizable]="false" appendTo="body" [modal]="true"
     [breakpoints]="{'1199px': '100vw'}" [style]="{width: '1210px'}" styleClass="no-header-modal" [closeOnEscape]="true"
     (onHide)="closeDialogEvent()">

     <ng-template pTemplate="header" styleClass="hidden"></ng-template>
     <ng-template pTemplate="content" styleClass="p-0">
       <ng-container *ngIf="(clientData$ | async) as $">
         <form [formGroup]="editProfileFormGroup" (ngSubmit)="updateProfile()">
           <!-- Header -->
           <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

             <div class="flex align-items-center">
               <h2 class="mb-0 text-base md:text-lg font-normal">{{'EDIT_CLIENT_PROFILE.EDIT_PROFILE_OF' | translate}}
                 <strong>{{FullName.value | titlecase}}</strong> <span class="text-xs text-gray-600">- {{$.id}}</span>
               </h2>
             </div>
             <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
               (click)="editProfileModal = false"></div>
           </div>
           <!-- Header -->


           <!-- Content -->
           <div class="flex">

             <!-- Left Side-->
             <div class="border-right-1 border-gray-300 lg:w-8 w-full">

               <!-- Modal Content -->
               <div class="py-2 md:py-4 px-3 surface-100">

                 <div class="grid my-0">

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.MOBILE_NUMBER' | translate}}<sup class="p-error">*</sup></h4>
                     <input type="tel" class="w-full text-sm" pInputText formControlName="mobileNumber" />
                     <app-error-msg *ngIf="MobileNumber.touched && MobileNumber.invalid" [errors]="MobileNumber.errors"
                       [fieldLabel]="'CLIENT_PROFILE.MOBILE_NUMBER' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.FULL_NAME' | translate}}<sup class="p-error">*</sup></h4>
                     <input type="text" class="w-full text-sm" pInputText formControlName="fullName" />
                     <app-error-msg *ngIf="FullName.touched && FullName.invalid" [errors]="FullName.errors"
                       [fieldLabel]="'CLIENT_PROFILE.FULL_NAME' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.GENDER' | translate}} <sup class="p-error">*</sup></h4>
                     <p-dropdown [options]="EnumGender" formControlName="gender" optionLabel="value" filterBy="value"
                       styleClass="w-full">
                       <ng-template pTemplate="selectedItem">
                         <div class="text-sm" *ngIf="Gender.value">
                           {{Gender.value.value}}
                         </div>
                       </ng-template>
                       <ng-template let-gender pTemplate="item">
                         <div class="text-sm">{{gender.value}}</div>
                       </ng-template>
                     </p-dropdown>
                     <app-error-msg *ngIf="Gender.touched && Gender.invalid" [errors]="Gender.errors"
                       [fieldLabel]="'CLIENT_PROFILE.GENDER' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.EMAIL_ID' | translate}}</h4>
                     <input type="email" class="w-full text-sm" pInputText formControlName="emailId" />
                     <app-error-msg *ngIf="EmailId.touched && EmailId.invalid" [errors]="EmailId.errors"
                       [fieldLabel]="'CLIENT_PROFILE.EMAIL_ID' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.CHANNEL' | translate}}</h4>
                     <div class="p-buttonset white-space-nowrap">

                       <button type="button" *ngFor="let channelOption of channelCollection"
                         class="p-button-primary border-right-1 border-white md:w-3 text-sm"
                         [ngClass]="{'p-button-primary': channelOption.channel === Channel.value.channel,'p-button-secondary': channelOption.channel !== Channel.value.channel}"
                         (click)="Channel = channelOption" pButton pRipple label="{{channelOption.channel}}"></button>
                     </div>
                     <app-error-msg *ngIf="Channel.touched && Channel.invalid" [errors]="Channel.errors"
                       [fieldLabel]="'CLIENT_PROFILE.CHANNEL' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->


                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.ENQUIRY_SOURCE' | translate}}</h4>
                     <p-dropdown [options]="Source" formControlName="source" placeholder="Select source"
                       styleClass="w-full" [disabled]="DisableSource">
                       <ng-template pTemplate="selectedItem">
                         <div class="text-sm" *ngIf="SourceControl.value">
                           {{SourceControl.value}}
                         </div>
                       </ng-template>
                       <ng-template let-source pTemplate="item">
                         <div class="text-sm">{{source}}</div>
                       </ng-template>
                     </p-dropdown>
                     <app-error-msg *ngIf="SourceControl.touched && SourceControl.invalid"
                       [errors]="SourceControl.errors" [fieldLabel]="'CLIENT_PROFILE.ENQUIRY_SOURCE' | translate">
                     </app-error-msg>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="mb-2 col-12 md:col-6">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.PROMOTIONAL_PREFRENCE' | translate}} <sup class="p-error">*</sup>
                     </h4>

                     <div class="p-buttonset white-space-nowrap">
                       <button type="button" icon="pi pi-envelope" class=" border-right-1 border-white text-sm" pButton
                         pRipple label="Email" (click)="promotionalPreferenceSelection('web')"
                         [ngClass]="promotionPreferenceMap.has('web') ? 'p-button-primary': 'p-button-secondary'"></button>
                       <button type="button" icon="pi pi-comments" class=" border-right-1 border-white text-sm" pButton
                         pRipple label="SMS" (click)="promotionalPreferenceSelection('sms')"
                         [ngClass]="promotionPreferenceMap.has('sms') ? 'p-button-primary': 'p-button-secondary'"></button>
                       <button type="button" icon="pi pi-phone" class=" border-right-1 border-white text-sm" pButton
                         pRipple label="Phone" (click)="promotionalPreferenceSelection('phone')"
                         [ngClass]="promotionPreferenceMap.has('phone') ? 'p-button-primary': 'p-button-secondary'"></button>

                       <app-error-msg *ngIf="PromotionPreferance.touched && PromotionPreferance.invalid"
                         [errors]="PromotionPreferance.errors"
                         [fieldLabel]="'CLIENT_PROFILE.PROMOTION_PREFERENCE' | translate">
                       </app-error-msg>
                     </div>
                   </div>
                   <!-- Column -->

                   <!-- Column -->
                   <div class="col-12 md:col-5">
                     <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                       {{'EDIT_CLIENT_PROFILE.CHANGE_PROFILE_PIC' | translate}}</h4>
                     <div class="flex align-items-center mt-3">
                       <p-avatar size="large" shape="circle" [image]="ClientProfile.value"></p-avatar>
                       <input type="file" [hidden]="true" #uploadClientImage (change)="imageUpload()">
                       <button pButton pRipple type="button" label="Change" (click)="uploadClientImage.click()"
                         class="text-sm p-button-text ml-2"></button>
                     </div>
                   </div>
                   <!-- Column -->

                 </div>

               </div>
               <!-- Modal Content -->

               <!-- Modal Footer -->
               <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
                 <div class="flex align-items-center justify-content-between">
                   <div class="w-4">
                     <p-button type="button" [label]="'EDIT_CLIENT_PROFILE.CANCEL' | translate"
                       styleClass="p-button-link text-sm" (click)="editProfileModal = false">
                     </p-button>
                   </div>
                   <div class="w-8 text-right">
                     <button type="submit" class="text-sm" [disabled]="!editProfileFormGroup.valid" pButton pRipple
                       [label]="'EDIT_CLIENT_PROFILE.SAVE_CHANGES' | translate"></button>
                   </div>
                 </div>
               </div>
               <!-- Modal Footer -->

             </div>
             <!-- Left Side -->

             <!-- Right Side -->
             <div class="pl-3 w-4 hidden lg:block text-right">
               <img width="370" src="/assets/img/edit-profile-img.svg" alt="Image" />
             </div>
             <!-- Right Side -->

           </div>
           <!-- Content -->
         </form>
       </ng-container>





     </ng-template>

   </p-dialog>
   <!-- Edit Profile Modal -->


   <p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>
