import { createAction, props } from '@ngrx/store';


/* COMPANY DETAILS*/
export const FETCHED_COMPANY_DETAILS = createAction(
    'P_FETCHEDCOMPANYDETAILS',
    props<{ companyDetails: any }>()
);

export const ERROR_COMPANY_DETAILS = createAction(
    'P_ERRORCOMPANYDETAILS',
    props<{ err: any }>()
);

export const SELECT_LANGUAGE = createAction(
    'P_SELECTLANGUAGE',
    props<{ lang: any }>()
);

export const DIRECTION_DETAILS = createAction(
    'P_DIRECTIONDETAILS',
    props<{ dir: any }>()
);
