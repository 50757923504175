import { BehaviorSubject, of, combineLatest, forkJoin, Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  HostListener,
  Inject,
  OnDestroy,
  ComponentRef,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipModule } from 'primeng/chip';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { GridCellModule } from './utility/grid-cell/grid-cell.component';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import {EmptyStateContainerComponentModule} from './empty-state/empty-state-container/empty-state-container.component'
import { HttpClient } from '@angular/common/http';
import {SkeletonModule} from 'primeng/skeleton';
import {VirtualScroller, VirtualScrollerModule} from 'primeng/virtualscroller';
import {ProgressBarModule} from 'primeng/progressbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LazyLoadEvent } from 'primeng/api';
import { GridData } from '@fitness-force/enum';
import { ScrollingModule } from '@angular/cdk/scrolling';
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() columns = new BehaviorSubject<[]>([]);
    @Input() columnData  = new BehaviorSubject([])
    @Input() EmptyState =  new BehaviorSubject([])
    @Input() totalRecords =  new BehaviorSubject({totalPage: 10, totalItems: 10, currentPage: 0,reset:true})
    @Input() currentPage = new BehaviorSubject(null)
    @Input() selectable = new BehaviorSubject(true);
    isAllSelected = new BehaviorSubject(false)
    @Input() scrollHeight="flex";
    @Input() headerClasses=" bg-gray-100 font-bold border-none white-space-nowrap uppercase"
    @Input() tableClasses='p-0 shadow-2 my-3';
    @Input() groupMode=null;
    @Input() groupRows=null;
    @Input() paginator=true;
    @Input() loading=false;
   selectedRows = new BehaviorSubject(new Map());


    @Output() emitValue = new EventEmitter()
    @Output() emitSelectValue = new EventEmitter()
    @Output() onAction = new EventEmitter()
    @Output() loadNextData =  new EventEmitter()



  itemsPerPage = 10;
  // loading: boolean = true;
  newPage = 0
  pageSize:number = GridData.DefaultPageSize;
  value: boolean | undefined;

  cols: any[] | undefined;
  dataloaded: any = new BehaviorSubject([]);
  selectedValue: string | undefined;

  showCalendar: boolean = false;
  selectedTaskCategory: { name: string } | undefined;

  calendarInput: Date | undefined;

  first = 0;
  subscriptions: Subscription[] | any = []
  isInfinityScroll =  new BehaviorSubject(false)
  ref:ComponentRef<any>;

  @ViewChild('vs') vs: VirtualScroller;
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) private document: Document
    ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.columns)
    this.subscriptions.push(this.columnData)
    this.subscriptions.push(this.EmptyState)
    this.subscriptions.push(this.totalRecords)
    this.totalRecords.subscribe((value:any)=>{
      if(value.reset) this.first=0;
    })

  }
  ngAfterViewInit() {
}

  setAsObserVable(data:any){
    return of(data)
  }
  setCheckBoxValue(i:number,d:any){

    return Object.assign(d, {index: i})
  }
  trackByFn(index:number, item:any) {
    return index; // or item.id
  }
  goToNextPage(e:any){
    this.pageSize = e.rows
    let pn=e.first?(e.first/this.pageSize)+1:1;
    this.loadNextData.emit({ps: this.pageSize, pn})

  }
  selectedRow(columndata: any, selection: boolean) {
    if(selection){
      let tempMap=this.selectedRows.value;
      tempMap.set(columndata.data.index, columndata.data);
      this.selectedRows.next(tempMap);
      // TODO : change  isAllSelected event to multi select..
      if(tempMap.size>=2){
        this.isAllSelected.next(true);
      }else{
        this.isAllSelected.next(false);
      }

    }
    else
    {
      let tempMap=this.selectedRows.value;
      tempMap.delete(columndata.data.index);
      this.selectedRows.next(tempMap);
      if(tempMap.size>=2){
        this.isAllSelected.next(true);
      }else{
        this.isAllSelected.next(false);
      }
    }
    this.emitValue.emit({ data: columndata, selection: selection });
  }

  SelectAllRow(e: any) {
    if(e.checked){
      this.isAllSelected.next(true)
    } else {
      this.isAllSelected.next(false)
    }
    this.emitSelectValue.emit(e);

  }

  onActionEvent(event: any) {
    this.onAction.emit(event);
  }

  // TESTING PENDING FOR WRAPPING in single event ..
  emptyAction(event:any){
    this.onAction.emit(event);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe())
  }
}

@NgModule({
  declarations: [DataGridComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    DropdownModule,
    ButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    ChipModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    TableModule,
    DialogModule,
    CalendarModule,
    RippleModule,
    StyleClassModule,
    GridCellModule,
    SkeletonModule,
    ProgressBarModule,
    VirtualScrollerModule,
    ScrollingModule,
    EmptyStateContainerComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),

  ],
  exports: [DataGridComponent],
})
export class DataGridComponentModule {}
