import { PayModeLevel1, PayModeLevel2, Status } from '@fitness-force/enum';

export const CONSTANTS_DATA = {
  ALL_LANGUAGES: [
    { COUNTRY: 'us', LANG_CODE: 'en', LANG_ABBRE: 'ENGLISH' },
    { COUNTRY: 'ar', LANG_CODE: 'ar', LANG_ABBRE: 'ARABIC' },
    { COUNTRY: 'fr', LANG_CODE: 'fr', LANG_ABBRE: 'FRENCH' },
    { COUNTRY: 'sp', LANG_CODE: 'sp', LANG_ABBRE: 'SPANISH' },
    { COUNTRY: 'gr', LANG_CODE: 'gr', LANG_ABBRE: 'GERMAN' },
    { COUNTRY: 'in', LANG_CODE: 'hi', LANG_ABBRE: 'HINDI' },
  ],
  SEARCH_PROVIDERS: [
    { providerName: 'clients', pagination: { count: 4, offset: 0 } },
    { providerName: 'invoices', pagination: { count: 4, offset: 0 } },
    { providerName: 'actions', pagination: { count: 4, offset: 0 } },
    { providerName: 'kbdocs', pagination: { count: 4, offset: 0 } },
  ],
  TABLE_PAGE_INFO: {
    CURRENT_PAGE: 'currentPage',
    TOTAL_ITEMS: 'totalItems',
    TOTAL_PAGE: 'toalPages',
  },
  DOMAIN_INFO: { DOMAIN: 'fitnessforcenxt.com' },
  DEFAULT: { LOGIN_SUCCESS_REDIRECTION: '/crm/sales-pipeline' },
  LOST_DEALS_OTHER_REASONS: [
    { name: 'QUALITY OF FACILITY' },
    { name: 'CLUB LOCATION' },
    { name: 'Hours of operation' },
    { name: 'Fitness Equipment' },
  ],
  ALL_SOURCES: [
    {
      name: 'Online',
      items: [
        { name: 'Facebook' },
        { name: 'Website' },
        { name: 'Instagram' },
        { name: 'Class Pass' },
        { name: 'Gym Pass' },
      ],
    },
    {
      name: 'Phone',
      items: [
        { name: 'Hoarding' },
        { name: 'Website' },
        { name: 'Referral' },
        { name: 'Passing By' },
      ],
    },
    {
      name: 'Walk-In',
      items: [
        { name: 'Hoarding' },
        { name: 'Referral' },
        { name: 'Passing By' },
      ],
    },
    {
      name: 'Data Entry / Gathered',
      items: [
        { name: 'Pop-up Store' },
        { name: 'Referral' },
        { name: 'Bulk Upload' },
      ],
    },
  ],

  PAYMENTMODE: {
    IN: [
      {
        id: PayModeLevel2.Cash,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Cash,
        name_primary_language: 'Cash',
        name_secondary_language: 'Cash',
        description_primary_language: 'This is Cash Payment mode',
        description_secondary_language: 'This is Cash Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Debit,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Card,
        name_primary_language: 'Debit Card',
        name_secondary_language: 'Debit Card',
        description_primary_language: 'This is Debit Card Payment mode',
        description_secondary_language: 'This is Debit Card Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Credit,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Card,
        name_primary_language: 'Credit Card',
        name_secondary_language: 'Credit Card',
        description_primary_language: 'This is Credit Card Payment mode',
        description_secondary_language: 'This is Credit Card Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.NetBanking,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.NetBanking,
        name_primary_language: 'Net Banking',
        name_secondary_language: 'Net Banking',
        description_primary_language: 'This is Net Banking Payment mode',
        description_secondary_language: 'This is Net Banking Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.UPI,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.UPI,
        name_primary_language: 'UPI',
        name_secondary_language: 'UPI',
        description_primary_language: 'This is UPI Payment mode',
        description_secondary_language: 'This is UPI Payment mode',
        status: Status.InActive,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.ACH,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.ACH,
        name_primary_language: 'AmazonPay',
        name_secondary_language: 'AmazonPay',
        description_primary_language: 'This is AmazonPay Payment mode',
        description_secondary_language: 'This is AmazonPay Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Cheque,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Cheque,
        name_primary_language: 'Cheque',
        name_secondary_language: 'Cheque',
        description_primary_language: 'This is Cheque Payment mode',
        description_secondary_language: 'This is Cheque Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
    ],
    USA: [
      {
        id: PayModeLevel2.Cash,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Cash,
        name_primary_language: 'Cash',
        name_secondary_language: 'Cash',
        description_primary_language: 'This is Cash Payment mode',
        description_secondary_language: 'This is Cash Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Debit,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Card,
        name_primary_language: 'Debit Card',
        name_secondary_language: 'Debit Card',
        description_primary_language: 'This is Debit Card Payment mode',
        description_secondary_language: 'This is Debit Card Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Credit,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Card,
        name_primary_language: 'Credit Card',
        name_secondary_language: 'Credit Card',
        description_primary_language: 'This is Credit Card Payment mode',
        description_secondary_language: 'This is Credit Card Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.NetBanking,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.NetBanking,
        name_primary_language: 'Net Banking',
        name_secondary_language: 'Net Banking',
        description_primary_language: 'This is Net Banking Payment mode',
        description_secondary_language: 'This is Net Banking Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.UPI,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.UPI,
        name_primary_language: 'UPI',
        name_secondary_language: 'UPI',
        description_primary_language: 'This is UPI Payment mode',
        description_secondary_language: 'This is UPI Payment mode',
        status: Status.InActive,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
      {
        id: PayModeLevel2.Cheque,
        tenant_id: 0,
        company_id: 0,
        type: PayModeLevel1.Cheque,
        name_primary_language: 'Cheque',
        name_secondary_language: 'Cheque',
        description_primary_language: 'This is Cheque Payment mode',
        description_secondary_language: 'This is Cheque Payment mode',
        status: Status.Active,
        created_by: 0,
        updated_by: 0,
        created_date: '',
        updated_date: '',
      },
    ],
  },
  MONTHS: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  COLORS: [
    'blue',
    'orange',
    'green',
    'purple',
    'pink',
    'cyan',
    'yellow',
    'indigo',
    'teal',
    'bluegray',
    'gray',
  ],
  PAYMENT_GATEWAY_VENDOR_KEY: {
    PAYENGINE: {
      PUBLIC_KEY: 'pk_test_8DhNGLrVIqVIEgSesm1qVYSVXCIAQwPz',
      jsSource:
        'https://console.payengine.dev/js/wc/js/payengine.min.js?key=pk_test_8DhNGLrVIqVIEgSesm1qVYSVXCIAQwPz',
    },
    RAZORPAY: {
      jsSource: 'https://checkout.razorpay.com/v1/checkout.js',
      partnerKey: 'rzp_test_partner_HmxcRUfoVVuLMO',
      accountId: 'acc_HogDxe2rfeFIso',
      themeColor: '#F37254',
    },
  },
};
