import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InplaceModule } from 'primeng/inplace';
import { CONSTANTS_CRM } from '@fitness-force/constants';
import {
  Country,
  ListServiceTypeResponse,
  OpportunityStageMasterResponse,
  TENANTSINTERFACE,
  UpdateOpportunityRequest,
  UpdateTask,
} from '@fitness-force/models';
import { ServiceTypeService } from 'libs/api/src/lib/serviceType/service-type.service';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { ClassAppointmentBehaviour, TaskAssignToType, TaskType } from '@fitness-force/enum';
import { HttpClient } from '@angular/common/http';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { CurrencyformatPipeModule } from '../../../../filters/currencyformat.pipe';
import { DateTimeFormatPipeModule } from '../../../../filters/datetimeformat.pipe';
import { StyleClassModule } from 'primeng/styleclass';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {
  COUNTRY_MASTER_STATE,
  STAFF_STATE,
  STAGE_MASTER_STATE,
  TENANTS_INFO,
} from 'apps/ff-frontend/src/app/state/app.state';
import { CommonService, TaskService, TimeconversionService } from '@fitness-force/api';
import { OpportunityService } from 'libs/api/src/lib/crm/opportunity.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, pairwise } from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { AssignedToComponentModule } from '../../../staff/assigned-to/assigned-to.component';

@Component({
  selector: 'app-task-opportunity-info',
  templateUrl: './task-opportunity-info.component.html',
  styleUrls: ['./task-opportunity-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class TaskOpportunityInfoComponent implements OnInit {
  @Input('taskOpportunityInfo') modelData: any;
  @Output('ErrorData') ErrorData: EventEmitter<any> = new EventEmitter();
  @Output('UpdatedData') UpdatedData: EventEmitter<any> = new EventEmitter();

  TaskApportunityInfoForm: FormGroup | any;
  convertibility: any = CONSTANTS_CRM.OPPORTUNITY_CONVERTITBILITY;
  outcome: any = CONSTANTS_CRM.TOUR_OUTCOME;
  selectedOutcome!: any;
  values: string[] | undefined;
  followUpDate: Date | undefined;
  contractEndDate: Date | undefined;
  mylookupservice: any;
  hideOnOutsideClick: any;
  assignedTo$ = new BehaviorSubject<number>(0);

  serviceTypeCollection!: any;
  assignedCollection!: Assigned[];
  opportunityStageCollection!: Option[];

  errMsg: Error | any;
  tenantId: number = 0;
  taskType: TaskType | any;
  taskNotes: string = '';

  taskTitleEdit: boolean = false;
  taskNotesEdit: boolean = false;
  dueDateEdit: boolean = false;
  amountEdit: boolean = false;
  expectedDateEdit: boolean = false;

  filtertedServiceType!: Option[];

  showErrorPopup$ = new BehaviorSubject(false);
  errorDataModal = [] as any;
  errorMessages = [] as any;
  country: Country;



  constructor(
    public store: Store,
    public translate: TranslateService,
    private fb: FormBuilder,
    private serviceType: ServiceTypeService,
    private taskService: TaskService,
    private opportunityService: OpportunityService,
    private messageService: MessageService,
    private dateTimeService: TimeconversionService,
    private commonService: CommonService
  ) {
    this.taskType = TaskType;
  }


  ngOnInit(): void {
    this.TaskApportunityInfoForm = this.fb.group({
      taskTitle: new FormControl(this.modelData.title ? this.modelData.title : this.modelData.latest_task.title),
      taskNotes: new FormControl(this.taskNotes),
      value_in_minor_currency: this.modelData.value_in_minor_currency ? new FormControl(this.commonService.ConvertAmountFromMinorCurrency(this.modelData.value_in_minor_currency)) : new FormControl(0),
      scheduled_date: new FormControl(
        this.dateTimeService.ConvertUTCToLocalDateTime(
          this.modelData.scheduled_date ? this.modelData.scheduled_date : this.modelData.latest_task.scheduled_date
        )
      ),
      expected_closure_date: this.modelData.expected_closure_date ? new FormControl(
        this.dateTimeService.ConvertUTCToLocalDateTime(
          this.modelData.expected_closure_date
        )) : new FormControl({ value: null, disabled: true })
      ,
      ddlselectedConvertibility: new FormControl(),
      ddlselectedOpportunity: new FormControl(),
      ddlselectedAssigned: new FormControl(),
      service_type_ids: new FormControl(),
    });
    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
      }
    });

    this.store.select(COUNTRY_MASTER_STATE).subscribe((country: Country) => {
      this.country = country;
    });

    this.GetTaskNotes();
    this.BindUsers();
    this.BindServiceType();
    // if(this.modelData.stage_master_id)
    this.BindOpportunityStageMaster();

    if (this.modelData) {
      let selectedConvertibility = this.convertibility.find(
        (s: any) => s.name == this.modelData.hot_warm_cold
      );
      if (this.modelData.hot_warm_cold)
        this.TaskApportunityInfoForm.controls.ddlselectedConvertibility.setValue(
          selectedConvertibility,
          { onlySelf: true }
        );
      else
        this.TaskApportunityInfoForm.controls.ddlselectedConvertibility.disable();

    }

    // this.SelectedAssignedFormControl
    //   .valueChanges
    //   .pipe(pairwise())
    //   .subscribe(([prev, next]: [any, any]) => {
    //     debugger;
    //     if (prev && next && prev.id != next.id) {
    //       this.UpdateAssignTo(event, this.TaskApportunityInfoForm);
    //     }
    //   });
  }

  getSelectedStaff(trainer: any) {
    this.UpdateAssignTo(trainer, this.TaskApportunityInfoForm)
  }

  GetTaskNotes() {
    this.taskService
      .GetTask(this.tenantId, this.modelData.latest_task ? this.modelData.latest_task.id : this.modelData.id)
      .subscribe({
        next: (data: any) => {
          this.taskNotes = data.notes;
          this.TaskApportunityInfoForm.controls.taskNotes.setValue(
            this.taskNotes,
            { onlySelf: true }
          );
        },
        error: (error: AppErrors) => {
          this.errorMessages = [];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
            this.errorMessages.push(error.originalError.message);
            this.errorDataModal = this.errorMessages;
            this.showErrorPopup$.next(true);
          } else {
            this.ReadErrorMessgaes(error);
          }
        },
      });
  }

  BindUsers(): void {
    if (this.modelData){
      this.assignedTo$.next(this.modelData?.latest_task?.assigned_to_staff_id?this.modelData?.latest_task?.assigned_to_staff_id:this.modelData?.assigned_to_staff_id);
    }

  }

  BindServiceType(): void {
    this.serviceType.GetServiceType(this.tenantId).subscribe({
      next: (serviceTypes: ListServiceTypeResponse) => {
        this.serviceTypeCollection = serviceTypes.items;
        if (this.modelData.service_type) {
          let selectedServiceType = serviceTypes.items.filter((allservice) => {
            return this.modelData.service_type.some(function (
              modelservice: any
            ) {
              return allservice.id === modelservice.id;
            });
          });
          if (this.modelData.service_type)
            this.TaskApportunityInfoForm.controls.service_type_ids.setValue(
              selectedServiceType
            );
          else this.TaskApportunityInfoForm.controls.service_type_ids.disable();
        }
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          this.errMsg = error.originalError.message;
        } else {
          this.errMsg = error.originalError.message;
        }
      },
    });
  }

  filterServiceType(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.serviceTypeCollection.length; i++) {
      let serviceType = this.serviceTypeCollection[i];
      if (
        serviceType.name_primary_language
          .toLowerCase()
          .indexOf(query.toLowerCase()) == 0 ||
        serviceType.name_secondary_language
          .toLowerCase()
          .indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(serviceType);
      }
    }

    this.filtertedServiceType = filtered;
  }

  BindOpportunityStageMaster(): void {
    this.store.select(STAGE_MASTER_STATE).subscribe((res: any) => {
      this.opportunityStageCollection = [];
      let opportunities = res as any;
      opportunities.forEach(
        (opportunityStage: OpportunityStageMasterResponse) => {
          this.opportunityStageCollection.push({
            name: opportunityStage.name_primary_language,
            value: opportunityStage.id,
          });
        }
      );
      if (this.modelData) {
        let selectedOpportunity = this.opportunityStageCollection.find(
          (s) => s.value == this.modelData.stage_master_id
        );

        if (this.modelData?.stage_master_id)
          this.TaskApportunityInfoForm.controls.ddlselectedOpportunity.setValue(
            selectedOpportunity,
            { onlySelf: true }
          );
        else
          this.TaskApportunityInfoForm.controls.ddlselectedOpportunity.disable();
      }
    });
  }

  async EditTaskTitle(form: FormGroup) {
    this.taskTitleEdit = false;
    let updateTask: UpdateTask = {} as UpdateTask;
    updateTask.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateTask.notes = form.value.taskNotes;
    updateTask.title = form.value.taskTitle;
    updateTask.priority = this.modelData.priority ? this.modelData.priority : this.modelData.latest_task.priority;
    updateTask.scheduled_date = this.modelData.latest_task ? this.modelData.latest_task.scheduled_date : this.modelData.scheduled_date;
    updateTask.category_id = this.modelData.latest_task ? this.modelData.latest_task.category_id : this.modelData.category_id;
    updateTask.assigned_to_type = TaskAssignToType.SpecificStaff;
    await this.UpdateTask(updateTask).then((res) => {
      if (res) {
        if (this.modelData.latest_task)
          this.modelData.latest_task.title = form.value.taskTitle;
        else this.modelData.title = form.value.taskTitle;
        this.UpdatedData.emit(this.modelData);
      } else
        this.TaskApportunityInfoForm.controls.taskTitle.setValue(
          this.modelData.latest_task ? this.modelData.latest_task.title : this.modelData.title,
          { onlySelf: true }
        );
    });
  }

  async EditTaskNotes(form: FormGroup) {
    this.taskNotesEdit = false;
    this.taskTitleEdit = false;
    let updateTask: UpdateTask = {} as UpdateTask;
    updateTask.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateTask.notes = form.value.taskNotes;
    updateTask.title = form.value.taskTitle;
    updateTask.priority = this.modelData.latest_task ? this.modelData.latest_task.priority : this.modelData.priority;;
    updateTask.scheduled_date = this.modelData.latest_task ? this.modelData.latest_task.scheduled_date : this.modelData.scheduled_date;;
    updateTask.category_id = this.modelData.latest_task ? this.modelData.latest_task.category_id : this.modelData.category_id;
    updateTask.assigned_to_type = TaskAssignToType.SpecificStaff;
    await this.UpdateTask(updateTask).then((res) => {
      if (res) {
        this.taskNotes = form.value.taskNotes;
        this.UpdatedData.emit(this.modelData);
      } else
        this.TaskApportunityInfoForm.controls.taskNotes.setValue(
          this.modelData.latest_task ? this.modelData.latest_task.title : this.modelData.title,
          { onlySelf: true }
        );
    });
  }


  async UpdateAssignTo(e: any, form: FormGroup) {
    let updateTask: UpdateTask = {} as UpdateTask;
    updateTask.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateTask.notes = form.value.taskNotes;
    updateTask.title = form.value.taskTitle;
    updateTask.priority = this.modelData.latest_task ? this.modelData.latest_task.priority : this.modelData.priority;
    updateTask.scheduled_date = this.modelData.latest_task ? this.modelData.latest_task.scheduled_date : this.modelData.scheduled_date;
    updateTask.category_id = this.modelData.latest_task ? this.modelData.latest_task.category_id : this.modelData.category_id;
    updateTask.assigned_to_type = TaskAssignToType.SpecificStaff;
    await this.UpdateTask(updateTask).then((res) => {
      if (!res) {
        let selectedAssigned = this.assignedCollection.find(
          (s) => s.value == this.modelData.assigned_to_staff_id
        );
        this.TaskApportunityInfoForm.controls.ddlselectedAssigned.setValue(
          selectedAssigned,
          { onlySelf: true }
        );
      } else {
        this.modelData.assigned_to_staff_id =
          form.value.ddlselectedAssigned.id;
      }
      this.UpdatedData.emit(this.modelData);
    });
  }

  async UpdateConvartability(e: any, form: FormGroup) {
    let updateOpportunity: UpdateOpportunityRequest = {} as UpdateOpportunityRequest;
    updateOpportunity.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateOpportunity.comments = this.modelData.comments;
    updateOpportunity.expected_closure_date = this.modelData.expected_closure_date;
    updateOpportunity.hot_warm_cold = form.value.ddlselectedConvertibility.name;
    updateOpportunity.name = this.modelData.name;
    updateOpportunity.service_type_ids = form.value.service_type_ids.map(
      (s: { id: any }) => {
        return s.id;
      }
    );
    updateOpportunity.stage_master_id = form.value.ddlselectedOpportunity.value;
    updateOpportunity.value_in_minor_currency = form.value.value_in_minor_currency;
    await this.UpdateOpportunity(updateOpportunity).then((res) => {
      if (!res) {
        let selectedConvertibility = this.convertibility.find(
          (s: any) => s.name == this.modelData.hot_warm_cold
        );
        this.TaskApportunityInfoForm.controls.dlselectedConvertibility.setValue(
          selectedConvertibility,
          { onlySelf: true }
        );
      } else
        this.modelData.hot_warm_cold = form.value.ddlselectedConvertibility.name;
      this.UpdatedData.emit(this.modelData);
    });
  }

  async UpdateOpportunityStatge(e: any, form: FormGroup) {
    let updateOpportunity: UpdateOpportunityRequest = {} as UpdateOpportunityRequest;
    updateOpportunity.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateOpportunity.comments = this.modelData.comments;
    updateOpportunity.expected_closure_date = this.modelData.expected_closure_date;
    updateOpportunity.hot_warm_cold = form.value.ddlselectedConvertibility.name;
    updateOpportunity.name = this.modelData.name;
    updateOpportunity.service_type_ids = form.value.service_type_ids.map(
      (s: { id: any }) => {
        return s.id;
      }
    );
    updateOpportunity.stage_master_id = form.value.ddlselectedOpportunity.value;
    updateOpportunity.value_in_minor_currency = form.value.value_in_minor_currency;
    await this.UpdateOpportunity(updateOpportunity).then((res) => {
      if (!res) {
        let selectedOpportunity = this.opportunityStageCollection.find(
          (s) => s.value == this.modelData.stage_master_id
        );
        this.TaskApportunityInfoForm.controls.ddlselectedOpportunity.setValue(
          selectedOpportunity,
          { onlySelf: true }
        );
      } else
        this.modelData.stage_master_id = form.value.ddlselectedOpportunity.value;
      this.UpdatedData.emit(this.modelData);
    });
  }

  async OnScheduleDateSelect(e: any) {
    let updateTask: UpdateTask = {} as UpdateTask;
    updateTask.assigned_to_staff_id = this.TaskApportunityInfoForm.value.ddlselectedAssigned.id;
    updateTask.notes = this.TaskApportunityInfoForm.value.taskNotes;
    updateTask.title = this.TaskApportunityInfoForm.value.taskTitle;
    updateTask.priority = this.modelData.latest_task ? this.modelData.latest_task.priority : this.modelData.priority;;

    updateTask.scheduled_date = e.toISOString();
    updateTask.category_id = this.modelData.latest_task ? this.modelData.latest_task.category_id : this.modelData.category_id;
    updateTask.assigned_to_type = TaskAssignToType.SpecificStaff;
    await this.UpdateTask(updateTask).then((res) => {
      if (res) {
        if (this.modelData.latest_task)
          this.modelData.latest_task.scheduled_date = e;
        else this.modelData.scheduled_date = e;
      } else {
        this.TaskApportunityInfoForm.controls.scheduled_date.setValue(
          new Date(this.modelData.latest_task ? this.modelData.latest_task.scheduled_date : this.modelData.scheduled_date)
        );
      }
      this.dueDateEdit = false;
      this.UpdatedData.emit(this.modelData);
    });
  }

  async OnExpectedClosureDateSelect(e: any) {
    let updateOpportunity: UpdateOpportunityRequest =
      {} as UpdateOpportunityRequest;
    updateOpportunity.assigned_to_staff_id = this.TaskApportunityInfoForm.value.ddlselectedAssigned.id;
    updateOpportunity.comments = this.modelData.comments;
    updateOpportunity.expected_closure_date = e.toISOString();
    updateOpportunity.hot_warm_cold = this.TaskApportunityInfoForm.value.ddlselectedConvertibility.name;
    updateOpportunity.name = this.modelData.name;
    updateOpportunity.service_type_ids = this.TaskApportunityInfoForm.value.service_type_ids.map(
      (s: { id: any }) => {
        return s.id;
      }
    );
    updateOpportunity.stage_master_id = this.TaskApportunityInfoForm.value.ddlselectedOpportunity.value;
    updateOpportunity.value_in_minor_currency = this.TaskApportunityInfoForm.value.value_in_minor_currency;
    await this.UpdateOpportunity(updateOpportunity).then((res) => {
      if (res) {
        this.modelData.expected_closure_date = e;
      } else {
        this.TaskApportunityInfoForm.controls.expected_closure_date.setValue(
          new Date(this.modelData.expected_closure_date)
        );
      }
      this.expectedDateEdit = false;
      this.UpdatedData.emit(this.modelData);
    });
  }

  async UpdateAmount(form: FormGroup) {
    let updateOpportunity: UpdateOpportunityRequest = {} as UpdateOpportunityRequest;
    updateOpportunity.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
    updateOpportunity.comments = this.modelData.comments;
    updateOpportunity.expected_closure_date = this.modelData.expected_closure_date;
    updateOpportunity.hot_warm_cold = form.value.ddlselectedConvertibility.name;
    updateOpportunity.name = this.modelData.name;
    updateOpportunity.service_type_ids = form.value.service_type_ids.map(
      (s: { id: any }) => {
        return s.id;
      }
    );
    updateOpportunity.stage_master_id = form.value.ddlselectedOpportunity.value;
    updateOpportunity.value_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(form.value.value_in_minor_currency);
    await this.UpdateOpportunity(updateOpportunity).then((res) => {
      if (res)
        this.modelData.value_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(form.value.value_in_minor_currency);
      else
        this.TaskApportunityInfoForm.controls.value_in_minor_currency.setValue(this.commonService.ConvertAmountFromMinorCurrency(this.modelData.value_in_minor_currency), { onlySelf: true });
      this.UpdatedData.emit(this.modelData);
    });
    this.amountEdit = false;
  }

  async UpdateServiceType(isRemoved: boolean, e: any, form: FormGroup) {
    if (isRemoved && form.controls.service_type_ids.value && form.controls.service_type_ids.value.length == 0) {
      this.translate.get('Shared.TASK-OPPORTUNITY-INFO.INTERESTEDIN_MIN_ERROR').subscribe((translation) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: translation });
      });
      form.controls.service_type_ids.setValue([e]);
      return;
    }
    else {
      setTimeout(async () => {
        let updateOpportunity: UpdateOpportunityRequest =
          {} as UpdateOpportunityRequest;
        updateOpportunity.assigned_to_staff_id = form.value.ddlselectedAssigned.id;
        updateOpportunity.comments = this.modelData.comments;
        updateOpportunity.expected_closure_date = this.modelData.expected_closure_date;
        updateOpportunity.hot_warm_cold = form.value.ddlselectedConvertibility.name;
        updateOpportunity.name = this.modelData.name;
        updateOpportunity.service_type_ids = form.value.service_type_ids.map(
          (s: { id: any }) => {
            return s.id;
          }
        );
        updateOpportunity.stage_master_id = form.value.ddlselectedOpportunity.value;
        updateOpportunity.value_in_minor_currency = form.value.value_in_minor_currency;
        await this.UpdateOpportunity(updateOpportunity).then((res) => {
          if (res) this.modelData.service_type = form.value.service_type_ids;
          else {
            let selectedServiceType = this.serviceTypeCollection.filter(
              (o: any) => {
                return this.modelData.service_type.some(function (o2: any) {
                  return o.id === o2.id;
                });
              }
            );
            this.TaskApportunityInfoForm.controls.service_type_ids.setValue(
              selectedServiceType
            );
          }
          this.UpdatedData.emit(this.modelData);
        });
      }, 3000);
    }
  }

  async UpdateTask(updateTask: UpdateTask): Promise<boolean> {
    let othis = this;
    let promise: Promise<boolean> = new Promise(function (resolve, reject) {
      othis.taskService
        .UpdateTaskService(
          othis.tenantId,
          othis.modelData.latest_task ? othis.modelData.latest_task.id : othis.modelData.id,
          updateTask
        )
        .subscribe({
          next: (data: any) => {
            othis.translate
              .get('Shared.TASK-OPPORTUNITY-INFO.TASK_UPDATE_MSG')
              .subscribe((translation) => {
                othis.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: translation,
                });
              });
            resolve(true);
          },
          error: (error: AppErrors) => {
            othis.errorMessages = [];
            if (error instanceof NotFoundError) {
              othis.errMsg = error.originalError.message;
              othis.errorMessages.push(error.originalError.message);
              othis.errorDataModal = othis.errorMessages;
              othis.showErrorPopup$.next(true);
            } else {
              othis.ReadErrorMessgaes(error);
            }
            resolve(false);
          },
        });
    });
    return promise;
  }

  UpdateOpportunity(updateOpportunity: UpdateOpportunityRequest) {
    let othis = this;
    let promise: Promise<boolean> = new Promise(function (resolve, reject) {
      othis.opportunityService
        .OpportunityUpdateService(
          othis.tenantId,
          othis.modelData.board_type,
          othis.modelData.id,
          updateOpportunity
        )
        .subscribe({
          next: (data: any) => {
            othis.translate
              .get('Shared.TASK-OPPORTUNITY-INFO.OPPT_UPDATE_MSG')
              .subscribe((translation) => {
                othis.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: translation,
                });
              });
            resolve(true);
          },
          error: (error: AppErrors) => {
            othis.errorMessages = [];
            if (error instanceof NotFoundError) {
              othis.errMsg = error.originalError.message;
              othis.errorMessages.push(error.originalError.message);
              othis.errorDataModal = othis.errorMessages;
              othis.showErrorPopup$.next(true);
            } else {
              othis.ReadErrorMessgaes(error);
            }
            resolve(false);
          },
        });
    });
    return promise;
  }

  ReadErrorMessgaes(error: any) {
    if (error.originalError.status == 500) {
      this.errorMessages.push(error.originalError.error.errorMessage);
    }
    if (
      error.originalError.status == 400 ||
      error.originalError.status == 409
    ) {
      if (error.originalError.error.errors) {
        error.originalError.error.errors.forEach((e: any) => {
          this.errorMessages.push(e.errorMessage);
        });
      } else {
        if (error.originalError.error.length > 0) {
          error.originalError.error.forEach((e: any) => {
            this.errorMessages.push(e.errorMessage);
          });
        } else {
          this.errorMessages.push(error.originalError.error.errorMessage);
        }
      }
    } else {
      error.originalError.error.forEach((e: any) => {
        this.errorMessages.push(e.errorMessage);
      });
    }
    this.errorDataModal = this.errorMessages;
    this.showErrorPopup$.next(true);
    this.ErrorData.emit({ errors: this.errorMessages, showErrors: true });
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup$?.next(false);
    this.errorMessages = [];
  }

  get SelectedOpportunity() {
    return this.TaskApportunityInfoForm.controls.ddlselectedOpportunity.value;
  }

  get SelectedConvertibility() {
    return this.TaskApportunityInfoForm.controls.ddlselectedConvertibility.value;
  }

  get SelectedAssigned() {
    return this.SelectedAssignedFormControl.value;
  }

  get SelectedAssignedFormControl(): FormControl {
    return this.TaskApportunityInfoForm.controls.ddlselectedAssigned as FormControl;
  }
}

@NgModule({
  declarations: [TaskOpportunityInfoComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ChipsModule,
    ChipModule,
    InplaceModule,
    StyleClassModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    ToastModule,
    CalendarModule,
    InputNumberModule,
    CurrencyformatPipeModule,
    DateTimeFormatPipeModule,
    AssignedToComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TaskOpportunityInfoComponent],
})
export class TaskOpportunityInfoComponentModule { }

interface Option {
  name: string;
  value: number;
}

interface Member {
  name: string;
  id: number;
}

interface Assigned {
  name: string;
  value: number;
  avatar: string;
}
