import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyInfoStoreInterface } from '@fitness-force/models';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FETCHED_COMPANY_DETAILS } from './state/actions/company.action';
import { COMPANY_INFO } from './state/app.state';

@Component({
  selector: 'fitness-force-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ff-paylink';
  subscription!: Subscription;

  constructor(
  ) { }

  ngOnInit(): void {
    this.componentInit();
  }

  componentInit() {
    //this.store.dispatch({ type: 'FETCHED_SUBSCRIPTION_PAYLOAD', uuid: '14ba1afe-1d7c-4c34-9d28-39d4fc807012' });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
