import { createAction, props } from "@ngrx/store";

export const FETCH_COUNTRY_MASTER_LIST = createAction(
    'P_FETCHCOUNTRYMASTERLIST',
    props<{ countries: any }>()
);

export const FETCH_COUNTRY_MASTER = createAction(
    'P_FETCHCOUNTRYMASTER',
    props<{ country: any }>()
);